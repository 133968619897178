import Vue from 'vue';
import Vuex from 'vuex';
import { message } from 'ant-design-vue';

import uploadManagerModule from '@/store/uploadManagerModule';
import projectsModule from '@/store/projectsModule';
import administrationModule from '@/store/administrationModule';
import billingModule from '@/store/billingModule';
import mutationsBasesModule from '@/store/mutationsBasesModule';
import router from '@/router';
import { $http } from '@/plugins/axios';


Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    // TODO: добавить проверки на данные из "localStorage"
    accessToken: localStorage.getItem('accessToken'),
    userId: localStorage.getItem('userId'),
    role: localStorage.getItem('role'),
    email: localStorage.getItem('email'),
    user_uuid: localStorage.getItem('user_uuid'),

    backup_accessToken: localStorage.getItem('backup_accessToken'),
    backup_userId: localStorage.getItem('backup_userId'),
    backup_role: localStorage.getItem('backup_role'),
    backup_email: localStorage.getItem('backup_email'),
    backup_user_uuid: localStorage.getItem('backup_user_uuid'),

    hints: {},
    settings: {},
  },
  getters: {
    logined(state) {
      return state.accessToken &&
        state.userId &&
        state.role &&
        state.user_uuid &&
        state.user_uuid;
    },
    loginedAs(state) {
      return state.backup_accessToken &&
        state.backup_userId &&
        state.backup_role &&
        state.backup_email;
    },
    getHintText(state) {
      return (key) => state.hints[key];
    },
  },
  mutations: {
    login(state, {
      access_token, user_id, role, email, user_uuid, 
    }) {
      state.accessToken = access_token;
      state.userId = user_id;
      state.role = role;
      state.email = email;
      state.user_uuid = user_uuid;
      localStorage.setItem('accessToken', access_token);
      localStorage.setItem('userId', user_id);
      localStorage.setItem('role', role);
      localStorage.setItem('email', email);
      localStorage.setItem('user_uuid', user_uuid);
      /**
       * Не в "logout",
       * потому что тут ещё удалится при случае захода под кем-то админом
       */
      sessionStorage.removeItem('Projects:pagination.current');
      sessionStorage.removeItem('Samples:pagination.current');
      sessionStorage.removeItem('Variants:pagination.current');
  
      $http.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
    },
    logout(state) {
      state.accessToken = null;
      state.userId = null;
      state.role = null;
      state.email = null;
      state.user_uuid = null;
  
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userId');
      localStorage.removeItem('role');
      localStorage.removeItem('email');
      localStorage.removeItem('user_uuid');
  
      delete $http.defaults.headers.common['Authorization'];
    },
    setBackup(state, {
      accessToken, userId, role, email, user_uuid, 
    }) {
      state.backup_accessToken = accessToken;
      state.backup_userId = userId;
      state.backup_role = role;
      state.backup_email = email;
      state.backup_user_uuid = user_uuid;
    },
    setHints(state, value) {
      state.hints = value;
    },
    setSettings(state, value) {
      state.settings = value;
    },
  },
  actions: {
    logout(store) {
      if (store.getters.loginedAs) {
        store.dispatch('logoutAs');
      } else {
        store.commit('logout');
        store.dispatch('logoutModules');
        router.push('/');
      }
    },
    logoutModules(store) {
      store.dispatch('uploadManager/logout');
      store.commit('projects/logout');
      store.commit('administration/logout');
      store.commit('billing/logout');
      store.commit('mutationsBases/logout');
    },
    loginAs({
      state: {
        accessToken, userId, role, email, user_uuid, 
      }, commit, dispatch, 
    }, loginData) {
      dispatch('logoutModules');

      localStorage.setItem('backup_accessToken', accessToken);
      localStorage.setItem('backup_userId', userId);
      localStorage.setItem('backup_role', role);
      localStorage.setItem('backup_email', email);
      localStorage.setItem('backup_user_uuid', user_uuid);
      commit('setBackup', {
        accessToken,
        userId,
        role,
        email,
        user_uuid, 
      });
      commit('login', loginData);
      dispatch('billing/getBalance');
    },
    logoutAs({
      state: {
        backup_accessToken,
        backup_userId,
        backup_role,
        backup_email,
        backup_user_uuid, 
      },
      commit,
      dispatch,
    }) {
      dispatch('logoutModules');

      commit('login', {
        access_token: backup_accessToken,
        user_id: backup_userId,
        role: backup_role,
        email: backup_email,
        user_uuid: backup_user_uuid,
      });
      dispatch('billing/getBalance');
      localStorage.removeItem('backup_accessToken');
      localStorage.removeItem('backup_userId');
      localStorage.removeItem('backup_role');
      localStorage.removeItem('backup_email');
      localStorage.removeItem('backup_user_uuid');
      commit('setBackup', {
        accessToken: null,
        userId: null,
        role: null,
        email: null,
        user_uuid: null,
      });
    },
    getHints(store) {
      $http.get('setups/hints-dict/')
        .then((response) => {
          store.commit('setHints', response.data);
        })
        .catch($http.ifNotCancel((error) => {
          message.error(
            $http.parseError(
              'Не удалось получить всплывающие подсказки',
              error,
            ),
            5,
          );
        }));
    },
    getSettings(store) {
      $http.get('setups/settings/')
        .then((response) => {
          store.commit('setSettings', response.data);
        })
        .catch((error) => {
          if ($http.isCancel(error)) {
            return;
          }
          message.error($http.parseError('Не удалось получить всплывающие подсказки', error), 5);
        });
    },
  },
  modules: {
    uploadManager: uploadManagerModule,
    projects: projectsModule,
    administration: administrationModule,
    billing: billingModule,
    mutationsBases: mutationsBasesModule,
  },
});
