import { objectToOptions } from '@/utils/objectToOptions';


/** @enum */
export const USER_ROLE = Object.freeze({
  user: 'user',
  admin: 'admin',
  support: 'support',
  expert: 'expert',
});
export const USER_ROLE_TRANSLATION = Object.freeze({
  [USER_ROLE.user]: 'Пользователь',
  [USER_ROLE.admin]: 'Администратор',
  [USER_ROLE.support]: 'Тех. поддержка',
  [USER_ROLE.expert]: 'Эксперт',
});
export const USER_ROLE_TRANSLATION_OPTIONS = objectToOptions(
  USER_ROLE_TRANSLATION,
);


export const DATE_FRONTEND_FORMAT = 'DD.MM.YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';


/** @enum */
export const MUTATION_TYPE = Object.freeze({
  germ: 'germ',
  somat: 'somat',
});
export const MUTATION_TYPE_TRANSLATION = Object.freeze({
  [MUTATION_TYPE.germ]: 'Герминальные',
  [MUTATION_TYPE.somat]: 'Соматические',
});
export const MUTATION_TYPE_TRANSLATION_OPTIONS = objectToOptions(
  MUTATION_TYPE_TRANSLATION,
);
export const MUTATION_TYPE_TRANSLATION_EXTENDED = {
  ...MUTATION_TYPE_TRANSLATION,
  [`${MUTATION_TYPE.germ}${MUTATION_TYPE.somat}`]: (
    `${MUTATION_TYPE_TRANSLATION.germ, MUTATION_TYPE_TRANSLATION.somat}`
  ),
};

/** @enum */
export const ANALYSIS_METHOD = {
  zond: 'zond',
  ampl: 'ampl',
};
export const ANALYSIS_METHOD_TRANSLATION = Object.freeze({
  [ANALYSIS_METHOD.zond]: 'Зонды',
  [ANALYSIS_METHOD.ampl]: 'Ампликоны',
});
export const ANALYSIS_METHOD_TRANSLATION_OPTIONS = objectToOptions(
  ANALYSIS_METHOD_TRANSLATION,
);

/** @enum */
export const PATHOGEN_CLASS = Object.freeze({
  NOCLASS: 'NOCLASS',
  CLASS1_BENIGN: 'CLASS1_BENIGN',
  CLASS2_LIKELYBENING: 'CLASS2_LIKELYBENING',
  CLASS3_VUS: 'CLASS3_VUS',
  CLASS4_LIKELYPATHOGINIC: 'CLASS4_LIKELYPATHOGINIC',
  CLASS5_PATHOGENIC: 'CLASS5_PATHOGENIC',
});
export const PATHOGEN_CLASS_TRANSLATION = Object.freeze({
  [PATHOGEN_CLASS.NOCLASS]: 'Без класса патогенности',
  [PATHOGEN_CLASS.CLASS1_BENIGN]: 'Класс патогенности 1',
  [PATHOGEN_CLASS.CLASS2_LIKELYBENING]: 'Класс патогенности 2',
  [PATHOGEN_CLASS.CLASS3_VUS]: 'Класс патогенности 3',
  [PATHOGEN_CLASS.CLASS4_LIKELYPATHOGINIC]: 'Класс патогенности 4',
  [PATHOGEN_CLASS.CLASS5_PATHOGENIC]: 'Класс патогенности 5',
});
export const PATHOGEN_CLASS_TRANSLATION_OPTIONS = objectToOptions(
  PATHOGEN_CLASS_TRANSLATION,
);

/** @enum */
export const CLINICAL_RELEVANCE = Object.freeze({
  1: '1',
  2: '2',
  3: '3',
  4: '4',
});
export const CLINICAL_RELEVANCE_TRANSLATION = Object.freeze({
  [CLINICAL_RELEVANCE[1]]: 'I класс',
  [CLINICAL_RELEVANCE[2]]: 'II класс',
  [CLINICAL_RELEVANCE[3]]: 'III класс',
  [CLINICAL_RELEVANCE[4]]: 'IV класс',
});
export const CLINICAL_RELEVANCE_TRANSLATION_OPTIONS = objectToOptions(
  CLINICAL_RELEVANCE_TRANSLATION,
);

export const computed$ = Symbol('computed$');

export const DASH = '—';

export const PUBLIC_OPTIONS = [
  {
    value: 0,
    label: 'Приватный',
  },
  {
    value: 1,
    label: 'Публичный',
  },
];
