/** @type {import('node_modules/vuex/types/index').Module} */
export default {
  namespaced: true,
  state: { selectedUser: null },
  mutations: {
    setSelectedUser(state, user) {
      state.selectedUser = user;
    },
    logout(state) {
      state.selectedUser = null;
    },
  },
};
