export const StaticNode = {
  functional: true,
  props: {
    node: {
      type: [Array, Object],
      required: true,
    },
  },
  render(h, context) {
    return context.props.node;
  },
};
