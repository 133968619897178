import cookie from 'js-cookie';
import round from 'lodash/round';
import { message } from 'ant-design-vue';
import Vue from 'vue';
import axios from 'axios';

import {
  $deselectCallbackFromSample,
  $uploadSession,
  stubLoadingState,
} from '@/components/UploadManagerCard/constants';
import { emitter } from '@/components/UploadManagerCard/emitter';
import {
  earray,
  eset,
  efunction,
} from '@/utils/empties';
import { $http } from '@/plugins/axios';
import { ArrayDeleteIfHas } from '@/utils/ArrayDeleteIfHas';
import translateBytes from '@/utils/translateBytes';
// import type { LoadFileWorker } from '@/stores/uploadManagerStore/loadFile.worker';
// import loadFileWorkerUrl from '@/stores/uploadManagerStore/loadFile.worker?worker&url';


const httpChunkLoader = axios.create();
delete httpChunkLoader.defaults.headers.put['Content-Type'];
const maxIntervalMs = 10000;


/**
 * @param {{ requestWithRetryTimeoutId: number }} fileLoadingState
 * @param {() => Promise<unknown>} request
 */
async function requestWithRetry(request, fileLoadingState) {
  let error;
  for (let index = 0; index < 5; index++) {
    const start = new Date();
    try {
      return await request();
    } catch (_error) {
      if ($http.isCancel(_error)) {
        throw _error;
      }
      error = _error;
      await new Promise((resolve) => {
        fileLoadingState.requestWithRetryTimeoutId = setTimeout(
          resolve,
          5000 - (new Date() - start),
        );
      });
    }
  }
  throw error;
}


const MAX_LOAD_FILES_WORKERS_COUNT = 3;
const loadFileWorkerPool/* : LoadFileWorker[] */ = [];
const freeLoadFileWorker/* : LoadFileWorker[] */ = [];

function getLoadFileWorker(loadingFileState/* : LoadingFileState */)/* : LoadFileWorker */ {
  const freeWorker = freeLoadFileWorker.shift();
  if (freeWorker) {
    freeWorker.currentFileLoadingState = loadingFileState;
    return freeWorker;
  }
  if (loadFileWorkerPool.length < MAX_LOAD_FILES_WORKERS_COUNT) {
    const worker = new Worker(
      /* loadFileWorkerUrl */new URL('@/store/loadFile.worker', import.meta.url),
      { type: 'module' },
    )/*  as LoadFileWorker */;
    loadFileWorkerPool.push(worker);
    worker.currentFileLoadingState = loadingFileState;
    return worker;
  }
  throw new Error('Превышено максимальное количество потоков загрузки файлов');
}

function returnLoadFileWorker(worker/* : LoadFileWorker */) {
  worker.currentFileLoadingState = undefined;
  worker.onerror = null;
  worker.onmessage = null;
  worker.onmessageerror = null;
  freeLoadFileWorker.push(worker);
}

/**
 * @typedef {import('./uploadManagerModule').ExtendedFile} ExtendedFile
 * @typedef {import('./uploadManagerModule').LoadingFileState} LoadingFileState
 * 
 * @param {ExtendedFile} file
 * @param {(location: unknown) => void} successLoadCallback
 * @param {() => void} errorLoadCallback
 * @return {LoadingFileState}
 */
function createFileLoadingState(
  file,
  successLoadCallback,
  errorLoadCallback,
) {
  const result = Vue.observable({
    loadedBytes: 0,
    status: 'normal',
    description: 'Ожидает загрузки',
  });
  // Нереактивные поля
  result.currentChunkLoadedBytes = 0;
  result.loadedChunks = 0;
  result.loadCanceler = $http.eAbortController;
  result.requestWithRetryTimeoutId = null;
  result.uploaded_parts = [];
  result.loadedAt = [];
  result.chunkSize = 0;
  result.upload_id = null;
  result.object_key = null;
  result.urlsToUpload = null;
  result.totalСhunks = -1;
  result.successLoadCallback = successLoadCallback;
  result.errorLoadCallback = errorLoadCallback;

  // Функции для работы с данными
  result.getLoadedTimeInterval = () => {
    const loadedAt = result.loadedAt;
    if (!loadedAt.length) {
      return null;
    }
    const currentTime = new Date();
    while (currentTime - loadedAt[0].time > maxIntervalMs) {
      loadedAt.shift();
      if (!loadedAt.length) {
        return null;
      }
    }
    return currentTime - loadedAt[0].time;
  };
  result.recalculateLoadedBytes = () => {
    result.loadedBytes = (
      result.loadedChunks *
      result.chunkSize +
      result.currentChunkLoadedBytes
    );
  };
  result.onUploadProgress = (loaded) => {
    result.currentChunkLoadedBytes = loaded;
    result.recalculateLoadedBytes();
    result.loadedAt.push({
      time: new Date(),
      bytes: result.loadedBytes,
    });
  };

  result.startLoad = () => {
    loadFile(file, result);
  };
  result.prepareToRestartLoading = () => {
    result.status = 'normal';
    result.description = 'Ожидает загрузки';
    /*
      Сброс загруженных частей, так как, возможно,
      при восстановлении после ошибки,
      следующее количество байт будет меньше предыдущего (до ошибки).
      И тогда
      "loadedAt[loadedAt.length - 1].bytes - loadedAt[0].bytes"
      даст отрицательный результат
    */
    result.loadedAt = [];
  };

  return result;
}


// interface GetDownloadProgressData {
//   object_key: string,
//   downloaded_chunks: number | null,
//   total_chunks: number | null,
//   file: {
//     client_size: number,
//     status: 'uploading' | 'error' | 'uploaded',
//   },
// }

function createFileCopyLoadingState(
  file/* : ExtendedFileCopy */,
  callbacks/* : LoadingFileState['callbacks'] */,
  withCopy/* : boolean */,
)/* : LoadingCopyState */ {
  const result = Vue.observable({
    loadedBytes: 0,
    status: 'normal',
    description: 'Ожидает загрузки',
  });
  /* const result: ReturnType<typeof createFileCopyLoadingState> =  */Object.assign(result, {
    fileName: file.name,

    // loadedBytes: shallowRef(0),
    // status: shallowRef('normal'),
    // description: shallowRef('Ожидает копирования'),

    // Нереактивные поля
    loadedChunks: 0,
    loadCanceler: $http.eAbortController,
    requestWithRetryTimeoutId: 0,
    object_key: null,
    totalСhunks: 1,
    // Функции для работы с данными
    startLoad() {
      result.loadCanceler = new AbortController();
      const requestOptions = { signal: result.loadCanceler.signal };

      $http.post(
        (
          withCopy ?
            `files/ext_storages/${file.storageId}/copy-file/` :
            `files/ext_storages/${file.storageId}/save-as-storage-link/`
        ),
        {
          path: file.path,
          size: file.size,
          upload_session: file[$uploadSession],
        },
        requestOptions,
      )
        .then(async (response) => {
          result.object_key = response.data.object_key;
          const fileId = withCopy ? response.data.file.id : response.data.id;
          if (withCopy) {
            const getDownloadProgressData = { object_keys: [result.object_key] };
            result.status/* .value */ = 'active';
            while (result.status/* .value */ === 'active') {
              // Получить данные о загрузке
              const downloadProgressData = (await $http.post/* <[GetDownloadProgressData]> */(
                'files/ext_storages/get-download-progress/',
                getDownloadProgressData,
                requestOptions,
              )).data[0];
              result.loadCanceler.signal.throwIfAborted();

              const serverStatus = downloadProgressData.file.status;
              if (serverStatus === 'error') {
                throw {
                  response: {
                    data: 'Ошибка копирования файла',
                    status: 400,
                  },
                };
              }

              result.loadedChunks = downloadProgressData.downloaded_chunks || 0;
              result.totalСhunks = downloadProgressData.total_chunks || 1;

              if (serverStatus === 'uploaded') {
                // Переход к завершению
                break;
              }

              // Посчитать примерное количество загруженных байт для полосы прогресса и описания
              const fileSize = downloadProgressData.file.client_size || file.size;
              result.loadedBytes/* .value */ = Math.ceil(
                fileSize * result.loadedChunks / result.totalСhunks,
              );
              result.description/* .value */ = (
                `${translateBytes(result.loadedBytes/* .value */)} из ${translateBytes(fileSize)} загружено`
              );

              // Подождать до следующего запроса
              await new Promise((resolve) => { setTimeout(resolve, 3000); });
              result.loadCanceler.signal.throwIfAborted();
            }
          }

          // Указание байт для красивого расчёта прогресса
          result.loadedBytes/* .value */ = file.size;
          result.status/* .value */ = 'success';
          result.description/* .value */ = 'Загрузка завершена';
          result.callbacks.success(
            fileId,
            file,
            result,
          );
        })
        .catch((error) => {
          if (!$http.isCancel(error)) {
            result.status/* .value */ = 'exception';
            result.description/* .value */ = $http.parseError(
              '',
              error,
            );
            result.callbacks.error(result);
          }
        });
    },
    prepareToRestartLoading() {
      result.status/* .value */ = 'normal';
      result.description/* .value */ = 'Ожидает копирования';
    },
    callbacks,
  });
  return result;
}

/**
 * @param {ExtendedFile} file
 * @param {LoadingFileState} fileLoadingState
 */
async function loadFile(
  file,
  fileLoadingState,
) {
  fileLoadingState.status = 'active';
  // Подготовка загрузки
  if (!(fileLoadingState.object_key && fileLoadingState.upload_id)) {
    try {
      fileLoadingState.loadCanceler = new AbortController();
      const response = await requestWithRetry(
        () => $http.post(
          'files/s3/start-multipart-upload/',
          {
            object_key: file.name,
            size: file.size,
            upload_session: file[$uploadSession],
          },
          { signal: fileLoadingState.loadCanceler.signal },
        ),
        fileLoadingState,
      );
      fileLoadingState.fileId = response.data.file.id;
      fileLoadingState.upload_id = response.data.UploadId;
      fileLoadingState.object_key = response.data.Key;

      fileLoadingState.loadCanceler.signal.throwIfAborted();
    } catch (error) {
      if (!$http.isCancel(error)) {
        fileLoadingState.status = 'exception';
        fileLoadingState.description = $http.parseError(
          'Ошибка подготовки загрузки',
          error,
        );
        fileLoadingState.errorLoadCallback(fileLoadingState);
      }
      return;
    }
  }

  // Начало загрузки
  if (!fileLoadingState.urlsToUpload) {
    try {
      fileLoadingState.loadCanceler.signal.throwIfAborted();

      fileLoadingState.loadCanceler = new AbortController();
      const response = await requestWithRetry(
        () => $http.post(
          'files/s3/get-multipart-upload-urls/',
          {
            upload_id: fileLoadingState.upload_id,
            object_key: fileLoadingState.object_key,
            size: file.size,
          },
          { signal: fileLoadingState.loadCanceler.signal },
        ),
        fileLoadingState,
      );

      fileLoadingState.urlsToUpload = response.data.urls;
      fileLoadingState.totalСhunks = fileLoadingState.urlsToUpload.length;
      // Если размер чанка больше размера файла,
      // то при подсчёт 100% загруженных байтов будет неправильное значение
      fileLoadingState.chunkSize = Math.min(
        response.data.chunk_size,
        file.size,
      );

      fileLoadingState.loadCanceler.signal.throwIfAborted();
    } catch (error) {
      if (!$http.isCancel(error)) {
        fileLoadingState.status = 'exception';
        fileLoadingState.description = $http.parseError(
          'Ошибка начала загрузки',
          error,
        );
        fileLoadingState.errorLoadCallback(fileLoadingState);
      }
      return;
    }
  }


  // Загрузка файла в отдельном потоке
  const worker = getLoadFileWorker(fileLoadingState);
  fileLoadingState.loadCanceler = new AbortController();
  fileLoadingState.loadCanceler.signal.addEventListener(
    'abort',
    () => { worker.postMessage({ type: 'abort' }); },
  );

  // Прослушивание событий Worker'а
  const workerLoadingWork = new Promise/* <void> */((resolve, reject) => {
    worker.addEventListener(
      'message',
      (event) => {
        if (event.data.type === 'loadEnd') {
          resolve();
        } else if (event.data.type === 'uploadProgress') {
          fileLoadingState.onUploadProgress(event.data.payload);
        } else if (event.data.type === 'partUploaded') {
          fileLoadingState.uploaded_parts.push(event.data.payload);
        } else if (event.data.type === 'loadError') {
          reject();
          if (!event.data.payload.isCancel) {
            fileLoadingState.status/* .value */ = 'exception';
            fileLoadingState.description/* .value */ = 'Ошибка при загрузке';
            fileLoadingState.errorLoadCallback/* .callbacks.error */(fileLoadingState);
          }
        } else if (event.data.type === 'chunkLoaded') {
          fileLoadingState.loadedChunks++;
          fileLoadingState.currentChunkLoadedBytes = 0;
          fileLoadingState.recalculateLoadedBytes();
        }
      },
    );
  });


  fileLoadingState.loadedAt.push({
    time: Date.now(),
    bytes: 0,
  });
  worker.postMessage(
    {
      type: 'load',
      payload: {
        file: file,
        chunkSize: fileLoadingState.chunkSize,
        loadedChunks: fileLoadingState.loadedChunks,
        urlsToUpload: fileLoadingState.urlsToUpload,
      },
    },
  );

  try {
    // Ожадание завершение работы Worker'а
    await workerLoadingWork;
  } catch (error) {
    return;
  } finally {
    returnLoadFileWorker(worker);
  }

  // Окончание загрузки
  try {
    fileLoadingState.loadCanceler.signal.throwIfAborted();

    await $http.post(
      'files/s3/complete-multipart-upload/',
      {
        upload_id: fileLoadingState.upload_id,
        object_key: fileLoadingState.object_key,
        parts: fileLoadingState.uploaded_parts,
      },
    );

    fileLoadingState.status = 'success';
    fileLoadingState.description = 'Загрузка завершена';

    fileLoadingState.loadCanceler.signal.throwIfAborted();
    fileLoadingState.successLoadCallback(
      fileLoadingState.fileId,
      // response.data.Location,
      file,
      fileLoadingState,
    );
  } catch (error) {
    if (!$http.isCancel(error)) {
      fileLoadingState.status = 'exception';
      fileLoadingState.description = $http.parseError(
        'Ошибка завершения загрузки',
        error,
      );
      fileLoadingState.errorLoadCallback(fileLoadingState);
    }
    return;
  }
}


/** @type {import('node_modules/vuex/types/index').Module} */
export default {
  namespaced: true,
  state: {
    MAX_LOAD_FILES_WORKERS_COUNT,

    uploadManagerModalVisible: false,
    isFileUploading: false,
    noAskAboutPrice: cookie.get('UploadManager:noAskAboutPrice') === 'true',
    uploadFilesInProject: null,

    getProjectsRequest: {
      promise: null,
      inProgress: false,
    },
    projects: earray,
    projectsSamplesUpdated: eset,

    /*
      TODO: после загрузки файлы больше не нужны
      и нет смысла держать из в оперативной памяти
    */
    /**
     * Список всех файлов
     * @type {ExtendedFile[]}
     */
    fileList: [],

    successLoadCallback: efunction,
    errorLoadCallback: efunction,
    /**
     * Состояние загрузки файлов
     * @type {Map<ExtendedFile['name'], LoadingFileState>}
     */
    filesLoadingState: new Map(),
    /**
     * Файлы по их именам
     * Не реактивно
     * @type {Map<ExtendedFile['name'], ExtendedFile>}
     */
    fileListIndexByNames: new Map(),
    /**
     * Невыбранные файлы
     * @type {Set<ExtendedFile['name']>}
     */
    notSelectedFiles: new Set(),

    // Файлы по состояниям в списке
    /*
      TODO: сделать нереактивными некоторые поля,
      потому что за их состоянием не надо следить.
      Например, "waitingFilesQueue"
    */
    /** @type {ExtendedFile['name']} */
    waitingFilesQueue: [],
    // TODO: Кажется, можно заменить на счётчик
    errorFilesSize: 0,
    /** @type {Set<ExtendedFile['name']>} */
    errorFiles: new Set(),
    /** @type {Set<ExtendedFile['name']>} */
    successFiles: new Set(),

    // Для расчётов цен
    loadingPrices: new Set(),
    totalPriceLoading: false,
    totalPrice: {
      value: 0,
      discount: undefined,
    },
  },
  mutations: {
    setLoadCallbacks(
      state,
      {
        successLoadCallback,
        errorLoadCallback,
      },
    ) {
      state.successLoadCallback = successLoadCallback;
      state.errorLoadCallback = errorLoadCallback;
    },

    /**
     * @param {ExtendedFile[]} files
     */
    fileList_push(state, files) {
      const fileList = state.fileList;
      const filesLoadingState = state.filesLoadingState;
      /** @type {Set} */
      const notSelectedFiles = state.notSelectedFiles;
      /** @type {Map} */
      const fileListIndexByNames = state.fileListIndexByNames;
      /** @type {ExtendedFile['name'][]} */
      const waitingFilesQueue = state.waitingFilesQueue;
      const successLoadCallback = state.successLoadCallback;
      const errorLoadCallback = state.errorLoadCallback;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fileList.push(file);
        filesLoadingState.set(
          file.name,
          createFileLoadingState(
            file,
            successLoadCallback,
            errorLoadCallback,
          ),
        );
        notSelectedFiles.add(file.name);
        fileListIndexByNames.set(file.name, file);
        waitingFilesQueue.push(file.name);
      }
      // Вызывать после всех добавлений, чтобы много раз не "$emit",
      // так как используется лишь для перерисовки
      emitter.$emit('updated:notSelectedFiles');
    },
    fileList_pushCopy(
      state,
      {
        files,
        withCopy, 
      },
    ) {
      const fileList = state.fileList;
      const filesLoadingState = state.filesLoadingState;
      const notSelectedFiles = state.notSelectedFiles;
      const fileListIndexByNames = state.fileListIndexByNames;
      const waitingFilesQueue = state.waitingFilesQueue;

      const callbacks = {
        success: state.successLoadCallback,
        error: state.errorLoadCallback,
      };
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fileList.push(file);
        filesLoadingState.set(
          file.name,
          createFileCopyLoadingState(
            file,
            /* copyCallbacks */
            callbacks,
            withCopy,
          ),
        );
        notSelectedFiles.add(file.name);
        fileListIndexByNames.set(file.name, file);
        waitingFilesQueue.push(file.name);
      }
      // Вызывать после всех добавлений, чтобы много раз не "$emit",
      // так как используется лишь для перерисовки
      emitter.$emit('updated:notSelectedFiles');
    },
    fileList_pushByLink(state, files) {
      const fileList = state.fileList;
      const filesLoadingState = state.filesLoadingState;
      const notSelectedFiles = state.notSelectedFiles;
      const fileListIndexByNames = state.fileListIndexByNames;
      const successFiles = state.successFiles;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        fileList.push(file);
        filesLoadingState.set(
          file.name,
          {
            ...stubLoadingState,
            // loadedBytes: { value: file.size }/*  as ShallowRef<number> */,
            loadedBytes: file.size,
          },
        );
        successFiles.add(file.name);
        notSelectedFiles.add(file.name);
        fileListIndexByNames.set(file.name, file);
      }
      // Вызывать после всех добавлений, чтобы много раз не "$emit",
      // так как используется лишь для перерисовки
      emitter.$emit('updated:notSelectedFiles');
    },

    fileList_splice(state, index) {
      /** @type {ExtendedFile} */
      const file = state.fileList[index];
      state.fileList.splice(index, 1);

      const callback = file[$deselectCallbackFromSample];
      callback && callback();

      state.filesLoadingState.delete(file.name);
      state.notSelectedFiles.delete(file.name);
      emitter.$emit('updated:notSelectedFiles');
      state.fileListIndexByNames.delete(file.name);

      ArrayDeleteIfHas(state.waitingFilesQueue, file.name);

      state.errorFilesSize -= state.errorFiles.has(file.name);
      state.errorFiles.delete(file.name);
      state.successFiles.delete(file.name);
    },
    fileList_clear(state) {
      const filesLoadingState = state.filesLoadingState;
      const fileList = state.fileList;
      for (let index = 0; index < fileList.length; index++) {
        const file = fileList[index];
        const callback = file[$deselectCallbackFromSample];
        callback && callback();

        // const fileLoadingState = filesLoadingState.get(file.name);
        // fileLoadingState.loadCanceler.cancel();
        // clearTimeout(fileLoadingState.requestWithRetryTimeoutId);
      }
      state.fileList = [];

      filesLoadingState.clear();
      state.notSelectedFiles.clear();
      emitter.$emit('updated:notSelectedFiles');

      state.fileListIndexByNames.clear();
      state.waitingFilesQueue = [];
      state.errorFiles.clear();
      state.errorFilesSize = 0;
      state.successFiles.clear();

      state.loadingPrices.clear();
      state.totalPriceLoading = false;
      state.totalPrice.value = 0;
      state.totalPrice.discount = undefined;
    },

    waitingFilesQueue_push(state, filename) {
      state.waitingFilesQueue.push(filename);
    },
    waitingFilesQueue_shift(state) {
      state.waitingFilesQueue.shift();
    },

    errorFiles_add(state, filename) {
      state.errorFiles.add(filename);
      state.errorFilesSize++;
    },
    errorFiles_delete(state, filename) {
      state.errorFiles.delete(filename);
      state.errorFilesSize--;
    },

    successFiles_add(state, filename) {
      state.successFiles.add(filename);
      emitter.$emit('add:successFiles');
    },

    notSelectedFiles_add(state, filename) {
      state.notSelectedFiles.add(filename);
      emitter.$emit('updated:notSelectedFiles');
    },
    notSelectedFiles_add_noEmit(state, filename) {
      state.notSelectedFiles.add(filename);
    },
    notSelectedFiles_delete(state, filename) {
      state.notSelectedFiles.delete(filename);
      emitter.$emit('updated:notSelectedFiles');
    },

    set_projects(state, value) {
      state.projects = value;
    },
    set_getProjectsRequest_promise(state, value) {
      state.getProjectsRequest.promise = value;
    },
    set_getProjectsRequest_inProgress(state, value) {
      state.getProjectsRequest.inProgress = value;
    },
    set_uploadManagerModalVisible(state, value) {
      state.uploadManagerModalVisible = value;
    },

    setIsFileUploading(state, value) {
      state.isFileUploading = value;
    },
    set_projectsSamplesUpdated(state, value) {
      state.projectsSamplesUpdated = value;
    },

    set_noAskAboutPrice(state, value) {
      state.noAskAboutPrice = value;
      cookie.set('UploadManager:noAskAboutPrice', value);
    },

    uploadFiles(state) {
      state.uploadFilesInProject = null;
      state.uploadManagerModalVisible = true;
    },
    uploadFilesInProject(state, project_id) {
      state.uploadFilesInProject = project_id;
      state.uploadManagerModalVisible = true;
    },

    loadingPrices_add(state, vueComponent) {
      state.loadingPrices.add(vueComponent);
      state.totalPriceLoading = true;
    },
    loadingPrices_delete(state, vueComponent) {
      state.loadingPrices.delete(vueComponent);
      state.totalPriceLoading = state.loadingPrices.size > 0;
    },

    totalPrice_value_increase(state, value) {
      state.totalPrice.value += value;
      state.totalPrice.value = round(state.totalPrice.value, 2);
    },
    totalPrice_value_decrease(state, value) {
      // Чтобы не уходить в минус
      if (value > state.totalPrice.value) {
        value = state.totalPrice.value;
      }
      state.totalPrice.value -= value;
      state.totalPrice.value = round(state.totalPrice.value, 2);
    },
    set_totalPrice_discount(state, value) {
      state.totalPrice.discount = value;
    },
  },
  actions: {
    /**
     * @param {boolean} [force=false]
     * @return {Promise}
     */
    getProjects(store, force) {
      if (store.state.getProjectsRequest.inProgress) {
        return store.state.getProjectsRequest.promise;
      }

      // TODO: Убрать ненужные кэширования
      if (store.state.projects.length && !force) {
        return Promise.resolve(store.state.projects);
      }

      store.commit('set_getProjectsRequest_inProgress', true);
      const request = $http.get('projects/projects/', {
        params: {
          page: 1,
          page_size: 1000,
        },
      })
        .then((response) => {
          const projects = response.data.results;
          store.commit('set_projects', projects);
          return projects;
        })
        .catch($http.ifNotCancel((error) => {
          message.error(
            $http.parseError(
              'Не удалось получить проекты',
              error,
            ),
            5,
          );
        }))
        .finally(() => {
          store.commit('set_getProjectsRequest_inProgress', false);
        });
      store.commit('set_getProjectsRequest_promise', request);
      return request;
    },
    logout(store) {
      const state = store.state;

      state.projectsSamplesUpdated = eset;
      state.uploadManagerModalVisible = false;
      state.isFileUploading = false

      state.getProjectsRequest.promise = null;
      state.getProjectsRequest.inProgress = false;
      state.projects = earray;
      store.commit('fileList_clear');
      state.uploadFilesInProject = null;
    },
  },
};
