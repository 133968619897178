import Vue from 'vue';
import VueRouter from 'vue-router';
import { message } from 'ant-design-vue';

import createLazyRoute from '@/router/createLazyRoute';
import store from '@/store';
import { USER_ROLE } from '@/constants';
import { previousPageInSessionStorage } from '@/router/previousPageInSessionStorage';


Vue.use(VueRouter);

const allRoles = Object.values(USER_ROLE);
const adminSupport = [
  USER_ROLE.admin,
  USER_ROLE.support,
];
const adminExpert = [
  USER_ROLE.admin,
  USER_ROLE.expert,
];

export const routes = [
  {
    path: '/',
    name: 'Логин',
    component: 'Login',
    meta: {
      requiresAuth: false,
      rolesHaveAccess: [],
    },
  },
  {
    path: '/projects',
    name: 'Проекты',
    component: 'Projects',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples',
    name: 'Образцы',
    component: 'Samples',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/table-mode',
    name: 'Табличный вид образцов',
    component: 'SamplesTableMode',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/quality',
    name: 'Качество образца',
    component: 'SampleQuality',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/coverage',
    name: 'Покрытие',
    component: 'Coverage',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
    children: [
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/schema',
        name: 'Схема покрытия',
        component: 'CoverageSchema',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/table',
        name: 'Таблица покрытия',
        component: 'CoverageTable',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/barchart',
        name: 'Гистограмма покрытия',
        component: 'CoverageBarchart',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/projects/:project_id/samples/:sample_id/coverage/log',
        name: 'Лог образца',
        component: 'CoverageSampleLog',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
    ],
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants',
    name: 'Варианты',
    component: 'Variants',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id',
    alias: '/projects/:project_id/samples/table-mode/:sample_id/:variant_id',
    name: 'Вариант',
    component: 'Variant',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id/search-in-other-samples',
    name: 'Поиск в других проектах',
    component: 'SearchInOtherProjects',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/:project_id/samples/:sample_id/variants/:variant_id/igv',
    name: 'Вариант IGV',
    component: 'VariantIGV',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/mutations-base',
    name: 'База мутаций',
    component: 'MutationsBase',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/mutations-base/:setId',
    name: 'База мутаций, набор',
    component: 'MutationsBaseSet',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/projects/phenotypes',
    name: 'Фенотипы',
    component: 'Phenotypes',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/files/list',
    component: 'Files',
    children: [
      {
        path: '',
        name: 'Файлы',
        component: 'FilesList',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
      {
        path: '/files/exports',
        name: 'Экспорт',
        component: 'FilesExports',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: allRoles,
        },
      },
    ],
  },
  {
    path: '/administration/users',
    component: 'Administration',
    children: [
      {
        path: '',
        name: 'Администрирование пользователей',
        component: 'AdministationUsers',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/users/:userId/operations',
        name: 'Операции пользователя',
        component: 'AdministationUserOperations',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/organizations',
        name: 'Организации',
        component: 'AdministationOrganizations',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/panels',
        name: 'Администрирование панелей',
        component: 'AdministationPanels',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/report-templates',
        name: 'Шаблоны отчётов',
        component: 'AdministationReportTemplates',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/plan-rates',
        name: 'Тарифные планы',
        component: 'AdministationPlanRates',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
      {
        path: '/administration/validation',
        name: 'Валидация',
        component: 'AdministationValidation',
        meta: {
          requiresAuth: true,
          rolesHaveAccess: adminSupport,
        },
      },
    ],
  },
  {
    path: '/billing',
    name: 'Баланс',
    component: 'Billing',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/account',
    name: 'Аккаунт',
    component: 'Account',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: allRoles,
    },
  },
  {
    path: '/search',
    name: 'Поиск',
    component: 'Search',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
  {
    path: '/samples',
    name: 'Образцы эксперта',
    component: 'ExpertSamples',
    meta: {
      requiresAuth: true,
      rolesHaveAccess: adminExpert,
    },
  },
];

const x0y0 = Object.freeze({
  x: 0,
  y: 0, 
});
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...routes.map(createLazyRoute),
    {
      path: '*',
      redirect: '/', 
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.name === from.name) {
      return false;
    }
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return x0y0;
  },
});

router.beforeEach((to, from, next) => {
  if ((to.fullPath === from.fullPath) && from.name) {
    next(false);

  } else if (!store.getters.logined && to.meta.requiresAuth) {
    store.commit('logout');
    next({ name: 'Логин' });

  } else if (store.getters.logined && !to.meta.requiresAuth) {
    next({ name: 'Проекты' });

  } else if (store.getters.logined && !to.meta.rolesHaveAccess.includes(store.state.role)) {
    message.error('У вас нет доступа к этой странице', 5);
    next({ name: 'Проекты' });

  } else {
    next();
  }
});


router.afterEach(previousPageInSessionStorage);

// router.afterEach((to) => {
//   Vue.nextTick(() => {
//     document.title = to.meta.title || 'Анализ Геномных Данных';
//   });
// });

export default router;
