import cookie from 'js-cookie';
import { message } from 'ant-design-vue';

import router from '@/router/index';
import { $http } from '@/plugins/axios';


/** @type {import('node_modules/vuex/types/index').Module} */
export default {
  namespaced: true,
  state: {
    project: null,
    sample: null,
    variant: null,
    variantData: null,
    noAskAboutSampleReanalyzePrice: cookie.get('projects:noAskAboutSampleReanalyzePrice') === 'true',
    noAskAboutSampleCreatePrice: cookie.get('projects:noAskAboutSampleCreatePrice') === 'true',
  },
  mutations: {
    setProject(state, value) {
      state.project = value;
    },
    setSample(state, value) {
      state.sample = value;
    },
    setVariant(state, value) {
      state.variant = value;
    },
    setVariantData(state, value) {
      state.variantData = value;
    },
    setNoAskAboutSampleReanalyzePrice(state, value) {
      state.noAskAboutSampleReanalyzePrice = value;
      cookie.set('projects:noAskAboutSampleReanalyzePrice', value);
    },
    setNoAskAboutSampleCreatePrice(state, value) {
      state.noAskAboutSampleCreatePrice = value;
      cookie.set('projects:noAskAboutSampleCreatePrice', value);
    },
    logout(state) {
      state.project = null;
      state.sample = null;
      state.variant = null;
      state.variantData = null;
    },
  },
  actions: {
    async getProject(store, project_id) {
      if (store.state.project && store.state.project.id === Number(project_id)) {
        return store.state.project;
      }

      store.commit('setProject', null);
      try {
        const response = await $http.get(`projects/projects/${project_id}/`);
        store.commit('setProject', response.data);
        return response.data;
      } catch (error) {
        if ($http.isCancel(error)) {
          throw undefined;
        }

        if (error && error.response && error.response.status === 404) {
          message.error('Такой проект не найден', 5);
          router.push({ name: 'Проекты' });
          throw undefined;
        }

        message.error(
          $http.parseError(
            `Не удалось получить данные проекта "${project_id}"`,
            error,
          ),
          5,
        );
        throw error;
      }
    },
    async getSample(store, sample_id) {
      if (store.state.sample && store.state.sample.id === Number(sample_id)) {
        return store.state.sample;
      }

      store.commit('setSample', null);
      try {
        const response = await $http.get(`samples/samples/${sample_id}/`);
        store.commit('setSample', response.data);
        return response.data;
      } catch (error) {
        if ($http.isCancel(error)) {
          throw undefined;
        }

        if (error && error.response && error.response.status === 404) {
          message.error('Такой образец не найден', 5);
          router.push({
            name: 'Образцы',
            params: { project_id: router.currentRoute.params.project_id },
          });
          throw undefined;
        }

        message.error(
          $http.parseError(
            `Не удалось получить данные образца "${sample_id}"`,
            error,
          ),
          5,
        );
        throw error;
      }
    },
    async getVariant(
      store,
      {
        variant_id,
        sample_id,
        project_id,
        with_transcripts,
      },
    ) {
      const project = await store.dispatch('getProject', project_id);
      const sample = await store.dispatch('getSample', sample_id);

      if (
        store.state.variant &&
        // У варианта должен сопадать проект
        store.state.variant.project_id === project.id &&
        // У варианта должен сопадать образец
        store.state.variant.sample_id === sample.id &&
        store.state.variant.id === variant_id  
      ) {
        return store.state.variantData;
      }

      store.commit('setVariant', null);
      try {
        const response = await $http.get('samples/variants-ch/', {
          params: {
            format: 'json',
            calc_project_freq: 1,
            project_id: project.id,
            sample_id: sample.id,
            id: variant_id,
            with_transcripts,
          },
        });
        const variant = response.data.results[0];
        if (!variant) {
          throw {
            response: {
              status: 404,
              data: { detail: 'Вариант не найден' },
            },
          };
        }
        variant.project_freq = response.data.project_freq;
        store.commit('setVariant', variant);
        store.commit('setVariantData', response.data);
        return response.data;
      } catch (error) {
        if ($http.isCancel(error)) {
          throw undefined;
        }

        if (error && error.response && error.response.status === 404) {
          message.error('Такой вариант не найден', 5);
          router.push({
            name: 'Варианты',
            params: {
              project_id,
              sample_id,
            },
          });
          throw undefined;
        }

        message.error(
          $http.parseError(
            `Не удалось получить данные варианта "${variant_id}"`,
            error,
          ),
          5,
        );
        throw error;
      }
    },
  },
};
