
import {
  defineComponent, 
  shallowRef,
  onBeforeUnmount, 
} from 'vue';
import {
  Menu,
  Icon,
  message,
} from 'ant-design-vue';
import { $http } from '@/plugins/axios';


export default defineComponent({
  name: 'InProgressMenuItem',
  components: {
    'a-menu-item': Menu.Item,
    'a-icon': Icon,
  },
  setup() {
    const taskInProgress = shallowRef(0);

    let getProgressTimeoutId = 0;
    let getProgressAbortController = new AbortController();

    function getProgress() {
      clearTimeout(getProgressTimeoutId);

      $http.get<{task_in_progress: number}>(
        'setups/progress/',
        { signal: getProgressAbortController.signal },
      )
        .then((response) => {
          if (response.config.signal !== getProgressAbortController.signal) {
            clearTimeout(getProgressTimeoutId);
            return;
          }
          taskInProgress.value = response.data.task_in_progress;
          getProgressTimeoutId = setTimeout(getProgress, 8000);
        })
        .catch((error) => {
          if ($http.isCancel(error)) {
            clearTimeout(getProgressTimeoutId);
            return;
          }
          message.error(
            $http.parseError(
              'Не удалось получить данные об обработки файлов',
              error,
            ),
            5,
          );
          getProgressTimeoutId = setTimeout(getProgress, 8000);
        });
    }

    getProgress();

    onBeforeUnmount(() => {
      getProgressAbortController = $http.eAbortController;
      clearTimeout(getProgressTimeoutId);
      getProgressAbortController.abort();
    });

    return { taskInProgress };
  },
});
