export const previousPageNameKey = 'previousPageName';
// export const previousPageKey = 'previousPage';

export function previousPageInSessionStorage(to, from) {
  if (from.name && to.name !== from.name) {
    sessionStorage.setItem(
      previousPageNameKey,
      from.name,
    );
    // sessionStorage.setItem(
    //   previousPageKey,
    //   JSON.stringify(from),
    // );
  }
}
