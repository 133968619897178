<template>
<a-config-provider :locale="ru_RU">
  <a-layout>
    <div
      v-if="$store.getters.loginedAs"
      class="text-center text-white"
      style="background-color: #ff4d4f;"
    >
      Выполнен вход под пользователем {{ $store.state.email }}.
      <a-button
        class="px-0"
        style="color: #2070ff"
        type="link"
        @click="logoutAs"
      >
        Вернуться в свой аккаунт.
      </a-button>
    </div>
    <a-layout-header
      v-if="page !== 'Логин'"
      class="page-menu--item-height"
    >
      <a-menu
        class="d-flex page-menu--item-height"
        theme="dark"
        mode="horizontal"
        :selected-keys="[page]"
      >
        <a-menu-item
          v-if="!$store.state.accessToken"
          class="page-menu--item-height"
        >
          <img
            class="logo-item"
            src="@/assets/black_logo.svg"
            alt="Лого"
          >
        </a-menu-item>

        <!-- Левые элементы меню -->
        <a-menu-item
          key="Проекты"
          class="page-menu--item-height"
          @click="toPage('Проекты')"
        >
          Проекты
        </a-menu-item>
        <a-menu-item
          v-if="$store.state.role === 'admin' || $store.state.role === 'expert'"
          key="Образцы эксперта"
          class="page-menu--item-height"
          @click="toPage('Образцы эксперта')"
        >
          Образцы
        </a-menu-item>
        <a-menu-item
          v-if="$store.state.role === 'admin' || $store.state.role === 'expert'"
          key="Поиск"
          class="page-menu--item-height"
          @click="toPage('Поиск')"
        >
          Поиск
        </a-menu-item>
        <a-menu-item
          key="База мутаций"
          class="page-menu--item-height"
          @click="toPage('База мутаций')"
        >
          База мутаций
        </a-menu-item>
        <a-menu-item
          key="Фенотипы"
          class="page-menu--item-height"
          @click="toPage('Фенотипы')"
        >
          Фенотипы
        </a-menu-item>
        <a-menu-item
          key="Файлы"
          class="page-menu--item-height"
          @click="toPage('Файлы')"
        >
          Файлы
        </a-menu-item>
        <a-menu-item
          key="Баланс"
          class="page-menu--item-height"
          @click="toPage('Баланс')"
        >
          {{ (
            $store.state.billing.balance ?
              `Баланс: ${$store.getters['billing/balanceText']}` :
              'Баланс'
          ) }}
        </a-menu-item>


        <a-menu-item
          class="px-0 flex-grow-1 flex-shrink-1 cursor-default"
          disabled
        />

        <!-- Правые элементы меню -->
        <InProgressMenuItem ref="InProgressMenuItem" />
        <a-menu-item
          v-if="uploadedBytesPercentText"
          class="page-menu--item-height"
          @click="openUploadManagerModal"
        >
          <a-icon type="cloud-upload" />:
          {{ uploadedBytesPercentText }}
        </a-menu-item>
        <a-menu-item
          v-if="(
            $store.state.role === 'admin' ||
            $store.state.role === 'support'
          )"
          key="Администрирование"
          class="page-menu--item-height"
          @click="toPage('Администрирование пользователей')"
        >
          Администрирование
        </a-menu-item>
        <a-menu-item
          key="Аккаунт"
          class="page-menu--item-height"
          @click="toPage('Аккаунт')"
        >
          {{ $store.state.email }}
        </a-menu-item>
        <a-menu-item
          key="Выйти"
          class="page-menu--item-height"
          @click="confirmLogout"
        >
          Выйти
        </a-menu-item>
      </a-menu>
    </a-layout-header>

    <a-layout-content class="view--wrap">
      <router-view
        :key="pageKey"
        class="view--page pa-4"
        @recreate="recreatePage"
      />

      <div
        v-if="$store.state.accessToken"
        v-show="!$store.getters.loginedAs"
        class="boot-manager-card--position"
        :style="{ right: uploadManagerCardShowIcon.cardRight }"
      >
        <a-tooltip placement="left">
          <template #title>
            {{ uploadManagerCardShowIcon.text }}
          </template>
          <a-icon
            :style="{
              right: uploadManagerCardShowIcon.iconRight,
              transform: uploadManagerCardShowIcon.iconRotate,
            }"
            type="left-circle"
            @click="uploadManagerCardShowIcon.click"
          />
        </a-tooltip>
        <UploadManagerCard
          :key="$store.state.userId"
          :update-uploaded-bytes-percent-text="setUploadedBytesPercentText"
          @task-created="onTaskCreated"
        />
      </div>
    </a-layout-content>
  </a-layout>
</a-config-provider>
</template>

<script>
import {
  // ConfigProvider,
  // Layout,
  Button,
  Menu,
  Icon,
  Tooltip,
} from 'ant-design-vue';
import ru_RU from 'ant-design-vue/lib/locale-provider/ru_RU';
// import moment from 'moment';
// import 'moment/locale/ru';

// import UploadManagerCard from '@/components/UploadManagerCard';
import UploadManagerCard from '@/components/UploadManagerCard/asyncComponent';
import { importMoment } from '@/plugins/moment/importMoment';
import {
  ConfigProvider,
  Layout,
} from '@/plugins/antd/asyncLoad';
import InProgressMenuItem from '@/components/InProgressMenuItem.vue';


// moment.locale('ru');
Promise.all([
  importMoment(),
  import('moment/locale/ru'),
])
  .then((result) => {
    result[0].locale('ru');
  });

const innerPageNames = Object.freeze({
  Проекты: 'Проекты',
  Образцы: 'Проекты',
  'Табличный вид образцов': 'Проекты',
  'Качество образца': 'Проекты',
  Варианты: 'Проекты',
  Вариант: 'Проекты',
  'Вариант IGV': 'Проекты',
  'Поиск в других проектах': 'Проекты',
  Поиск: 'Поиск',
  'База мутаций': 'База мутаций',
  'База мутаций, набор': 'База мутаций',
  Фенотипы: 'Фенотипы',
  Файлы: 'Файлы',
  Экспорт: 'Файлы',
  Администрирование: 'Администрирование',
  'Администрирование пользователей': 'Администрирование',
  Организации: 'Администрирование',
  'Операции пользователя': 'Администрирование',
  'Администрирование панелей': 'Администрирование',
  'Шаблоны отчётов': 'Администрирование',
  'Тарифные планы': 'Администрирование',
  Валидация: 'Администрирование',
  Баланс: 'Баланс',
  Аккаунт: 'Аккаунт',
  Покрытие: 'Проекты',
  'Схема покрытия': 'Проекты',
  'Таблица покрытия': 'Проекты',
  'Гистограмма покрытия': 'Проекты',
  'Лог образца': 'Проекты',
});


export default {
  name: 'App',
  components: {
    'a-config-provider': ConfigProvider,
    'a-layout': Layout,
    'a-layout-header': Layout.Header,
    'a-layout-content': Layout.Content,
    'a-button': Button,
    'a-menu': Menu,
    'a-menu-item': Menu.Item,
    'a-tooltip': Tooltip,
    'a-icon': Icon,

    UploadManagerCard,
    InProgressMenuItem,
  },
  data() {
    return {
      ru_RU,
      page: this.$route.name || 'Логин',
      uploadManagerCardMinimized: true,
      pageKey: 0,
      uploadedBytesPercentText: undefined,
    };
  },
  computed: {
    uploadManagerCardShowIcon() {
      return !this.uploadManagerCardMinimized ? {
        text: 'Свернуть',
        iconRotate: 'rotate(180deg)',
        click: () => {
          this.uploadManagerCardMinimized = true;
        },
        iconRight: '5px',
        cardRight: '10px',
      } : {
        text: 'Развернуть',
        iconRotate: 'rotate(0)',
        click: () => {
          this.uploadManagerCardMinimized = false;
        },
        iconRight: '5px',
        cardRight: '-290px',
      };
    },
  },
  methods: {
    recreatePage() {
      this.pageKey = (this.pageKey + 1) % 13;
    },
    logoutAs() {
      this.$store.dispatch('logoutAs');
      this.$router.push('/administration');
    },
    toPage(pageName) {
      this.$router.push({ name: pageName });
    },
    confirmLogout() {
      this.$confirm({
        title: 'Выход',
        content: (
          !this.$store.state.uploadManager.isFileUploading ?
            'Вы действительно хотите выйти?' :
            (
              'Все незагруженные файлы будут удалены из сеанса загрузки. ' +
              'Вы уверены, что хотите выйти страницу?'
            )
        ),
        okText: 'Да',
        cancelText: 'Нет',
        onOk: () => {
          this.$store.dispatch('logout');
          this.uploadedBytesPercentText = undefined;
          this.uploadManagerCardMinimized = false;
        },
      });
    },
    onTaskCreated() {
      this.uploadManagerCardMinimized = true;
      
      this.$refs.InProgressMenuItem &&
        this.$refs.InProgressMenuItem.getProgress();
    },
    setUploadedBytesPercentText(uploadedBytesPercentText) {
      this.uploadedBytesPercentText = uploadedBytesPercentText;
    },
    openUploadManagerModal() {
      this.uploadManagerCardMinimized = false;
      requestAnimationFrame(() => {
        this.$store.commit(
          'uploadManager/set_uploadManagerModalVisible',
          true,
        );
      });
    },
  },
  watch: {
    $route(to) {
      this.page = innerPageNames[to.name] || to.name;
    },
  },
  beforeCreate() {
    const queryParams = new URLSearchParams(location.search);
    const email_confirmation_token = queryParams.get(
      'email_confirmation_token',
    );
    if (email_confirmation_token) {
      if (this.$store.state.accessToken) {
        this.$message.success('Вы уже аутентифицированы.', 3);
      } else {
        const loading = this.$message.loading(
          'Запрос на подтверждение отправлен, подождите...',
          0,
        );
        this.$http.get(
          'users/confirm-email/',
          { params: { email_confirmation_token } },
        )
          .then(() => {
            this.$message.success(
              'Ваш E-mail подтвержден. Теперь вы можете войти в аккаунт.',
              3,
            );
          })
          .catch(this.$http.ifNotCancel((error) => {
            this.$message.error(
              this.$http.parseError('Не удалось подтвердить email', error),
              5,
            );
          }))
          .finally(loading);
      }
    }

    if (this.$store.state.accessToken) {
      this.$http.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.accessToken}`;

      this.$store.dispatch('getHints');
      this.$store.dispatch('getSettings');
      this.$store.dispatch('billing/getBalance');
    }
  },
};
</script>


<style>
h1 {
  font-size: 80px;
}
h2 {
  font-size: 55px;
}
h3 {
  font-size: 40px;
}
h4 {
  font-size: 30px;
}
h5 {
  font-size: 24px;
}
h6 {
  font-size: 18px;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.vertical-align-top {
  vertical-align: top !important;
}
.vertical-align-middle {
  vertical-align: middle !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.justify-space-around {
  justify-content: space-around !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.align-items-start {
  align-items: start !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.white-space-normal {
  white-space: normal !important;
}
.white-space-pre {
  white-space: pre !important;
}
.white-space-nowrap {
  white-space: nowrap !important;
}
.white-space-pre-wrap {
  white-space: pre-wrap !important;
}
.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}
.w-50 {
  width: 50% !important;
}
.h-100 {
  height: 100% !important;
}
.h-0 {
  height: 0 !important;
}
.h-28px {
  height: 28px !important;
}
.h-30px {
  height: 30px !important;
}
.h-200px {
  height: 200px !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.text-bold {
  font-weight: bold !important;
}
.overflow-x-auto {
  overflow-x: auto !important;
}
.overflow-y-auto {
  overflow-y: auto !important;
}
.opacity-0 {
  opacity: 0 !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.line-through {
  text-decoration: line-through;
}

body {
  font-family: Roboto !important;
  overflow-x: hidden;
}

.ant-alert-description {
  white-space: pre-wrap;
}
.ant-message-notice-content span {
  white-space: pre-wrap;
  text-align: start;
}
.ant-table-body {
  margin: 0 !important;
}
.popover-content-no-paddings .ant-popover-inner-content {
  padding: 0 !important;
}
.popover-title-min-width-unset >
.ant-popover-content >
.ant-popover-inner >
div >
.ant-popover-title {
  min-width: unset !important;
}


.ant-checkbox-wrapper >
.ant-checkbox >
.ant-checkbox-inner {
  border-radius: 4px !important;
}
.ant-checkbox-wrapper >
.ant-checkbox.ant-checkbox-indeterminate >
.ant-checkbox-inner,
.ant-checkbox-wrapper.ant-checkbox-wrapper-checked >
.ant-checkbox.ant-checkbox-checked >
.ant-checkbox-inner {
  border-color: var(--blue-dark-1) !important;
  background-color: #E9F4FF !important;
}
.ant-checkbox-wrapper >
.ant-checkbox.ant-checkbox-indeterminate >
.ant-checkbox-inner::after {
  width: 9px !important;
  height: 1px !important;
}
.ant-checkbox-wrapper >
.ant-checkbox.ant-checkbox-checked >
.ant-checkbox-inner {
  background-color: white !important;
}
.ant-checkbox-wrapper >
.ant-checkbox.ant-checkbox-checked >
.ant-checkbox-inner::after {
  border-color: var(--blue-dark-1) !important;
}

:root {
  --green: #2bd800;
  --red: #cd1b01;
  --yellow: #ffdd00;
  --gray: #808080;
  --gray-light-1: rgb(232, 232, 232);
  --blue: #4f93ff;
  --orange: #D15800;
  --green-light-1: #52c41a;
  /* --green-light-2: #d9f2d9; */
  --red-light-1: #f5222d;
  /* --red-light-2: #ff4d4f; */
  --blue-dark-1: #1890ff;
  /* --yellow-light-1: #fce8b2; */
}
.text-green {
  color: var(--green) !important;
}
.bg-green {
  background-color: var(--green) !important;
}
.text-red {
  color: var(--red) !important;
}
.bg-red {
  background-color: var(--red) !important;
}
.text-yellow {
  color: var(--yellow) !important;
}
.bg-yellow {
  background-color: var(--yellow) !important;
}
.text-gray {
  color: var(--gray) !important;
}
.bg-gray {
  background-color: var(--gray) !important;
}
.text-blue {
  color: var(--blue) !important;
}
.bg-blue {
  background-color: var(--blue) !important;
}
.text-orange {
  color: var(--orange) !important;
}
.bg-orange {
  background-color: var(--orange) !important;
}
.text-green-light-1 {
  color: var(--green-light-1) !important;
}
.bg-green-light-1 {
  background-color: var(--green-light-1) !important;
}
.text-red-light-1 {
  color: var(--red-light-1) !important;
}
.bg-red-light-1 {
  background-color: var(--red-light-1) !important;
}
/* .text-red-light-2 {
  color: var(--red-light-2) !important;
}
.bg-red-light-2 {
  background-color: var(--red-light-2) !important;
} */
.text-blue-dark-1 {
  color: var(--blue-dark-1) !important;
}
.bg-blue-dark-1 {
  background-color: var(--blue-dark-1) !important;
}
/* .text-yellow-light-1 {
  color: var(--yellow-light-1) !important;
}
.bg-yellow-light-1 {
  background-color: var(--yellow-light-1) !important;
} */
.no-bg-color {
  background-color: unset !important;
}

.icon-color {
  color: rgba(0, 0, 0, 0.25) !important;
}
.text-white {
  color: white !important;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
  color: #5b97c8;
}

.w-16px {
  width: 16px !important;
}
.w-20px {
  width: 20px !important;
}
.w-24px {
  width: 24px !important;
}
.w-48px {
  width: 48px !important;
}
.w-64px {
  width: 64px !important;
}
.w-90px {
  width: 90px !important;
}
.w-100px {
  width: 100px !important;
}
.w-125px {
  width: 125px !important;
}
.w-145px {
  width: 145px !important;
}
.w-150px {
  width: 150px !important;
}
.w-175px {
  width: 175px !important;
}
.w-200px {
  width: 200px !important;
}
.w-225px {
  width: 225px !important;
}
.w-300px {
  width: 300px !important;
}
.min-width-unset {
  min-width: unset !important;
}
.min-width-150px {
  min-width: 150px !important;
}
.width-unset {
  width: unset !important;
}
.max-width-unset {
  max-width: unset !important;
}

.font-size-9px {
  font-size: 9px !important;
}
.font-size-10px {
  font-size: 10px !important;
}
.font-size-12px {
  font-size: 12px !important;
}
.font-size-14px {
  font-size: 14px !important;
}
.font-size-16px {
  font-size: 16px !important;
}
.font-size-18px {
  font-size: 18px !important;
}
.font-size-20px {
  font-size: 20px !important;
}
/* .font-size-22px {
  font-size: 22px !important;
}
.font-size-24px {
  font-size: 24px !important;
} */
/* .font-size-28px {
  font-size: 28px !important;
} */
/* .font-size-32px {
  font-size: 32px !important;
} */

.rounded-4px {
  border-radius: 4px !important;
}
.no-rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.no-rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.no-rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.mr-2px {
  margin-right: 2px !important;
}
.mr-20px {
  margin-right: 20px !important;
}
.mr-32px {
  margin-right: 32px !important;
}
.mt-n24px {
  margin-top: -24px !important;
}
.mb-10px {
  margin-bottom: 10px !important;
}

/* .pt-1px {
  padding-top: 1px !important;
} */
.pt-2px {
  padding-top: 2px !important;
}
.pt-3px {
  padding-top: 3px !important;
}
.pt-14px {
  padding-top: 14px !important;
}

.no-transform {
  transform: none !important;
}
.no-transform-child * {
  transform: none !important;
}

.text-deleted {
  /* line-through text-gray */
  text-decoration: line-through;
  color: var(--gray);
}

.modal-max-width .ant-modal-wrap > .ant-modal {
  max-width: calc(100vw - 16px);
  /* padding-bottom: 0 !important; */
}

.second-level-menu-position {
  margin: -16px -66px 0 -66px;
  padding: 0 50px;
}

/* Пришлось вынести отдельно из-за кривой компиляции */
.small-table-font,
.small-table-font *:not(.ant-spin > *, .font-size-9px) {
  font-size: 12px !important;
}

.boot-manager-card--position {
  padding: 20px;
  position: fixed !important;
  bottom: 0px !important;
  transition: all 0.3s;
  z-index: 900;
}
.boot-manager-card--position > i.anticon {
  color: #1890FF;
  position: fixed !important;
  bottom: 80px !important;
  font-size: 20px;
  z-index: 1000;
  transition: all 0.3s;
}
.boot-manager-card--position .ant-card-head,
.boot-manager-card--position .ant-card-body {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
</style>
<style lang="scss">
.ant-select-dropdown {
  box-shadow: none !important;

  & > .ant-select-dropdown-content {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}


.small-table-paddings {
  .ant-table-small > .ant-table-content {
    .ant-table-header > table > .ant-table-thead,
    .ant-table-body > table > .ant-table-thead,
    .ant-table-scroll > .ant-table-header > table > .ant-table-thead,
    .ant-table-scroll > .ant-table-body > table > .ant-table-thead,
    .ant-table-fixed-left > .ant-table-header > table > .ant-table-thead,
    .ant-table-fixed-right > .ant-table-header > table > .ant-table-thead,
    .ant-table-fixed-left > .ant-table-body-outer >
      .ant-table-body-inner > table > .ant-table-thead,
    .ant-table-fixed-right > .ant-table-body-outer >
      .ant-table-body-inner > table > .ant-table-thead,
    .ant-table-header > table > .ant-table-tbody,

    .ant-table-body > table > .ant-table-tbody,
    .ant-table-scroll > .ant-table-header > table > .ant-table-tbody,
    .ant-table-scroll > .ant-table-body > table > .ant-table-tbody,
    .ant-table-fixed-left > .ant-table-header > table > .ant-table-tbody,
    .ant-table-fixed-right > .ant-table-header > table > .ant-table-tbody,
    .ant-table-fixed-left > .ant-table-body-outer >
      .ant-table-body-inner > table > .ant-table-tbody,
    .ant-table-fixed-right > .ant-table-body-outer >
      .ant-table-body-inner > table > .ant-table-tbody {
      tr {
        & > th,
        & > td {
          padding: 4px;
        }
      }
    }
  }
}

.ant-table.ant-table-small {
  border: none !important;
}

.ant-table >
.ant-table-content
.ant-table-body >
table {
  > thead.ant-table-thead > tr {
    background: linear-gradient(
      180deg,
      rgba(245, 250, 255, 0.66) 0%,
      #D7EBFF 100%
    ) !important;
    border-bottom: none !important;

    > th {
      background: none !important;
      border-bottom: none !important;
      color: #232526;
    }
  }
  > tbody.ant-table-tbody > tr {
    > td {
      border-bottom-color: #C3E1FE;
      color: #262626;
    }

    &.ant-table-row-selected > td {
      background: none !important;
    }
    /* &:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td */
    &:hover {
      background-color: #F8F8F8 !important;
      > td {
        background: none;
      }
    }
  }
}


$page-menu--item-height: 32px;

.view--wrap {
  $min-page-height: calc(100vh - #{$page-menu--item-height});

  background-color: #eef0f3;
  padding: 0 50px;
  min-height: $min-page-height;

  .view--page {
    min-height: $min-page-height;
    background-color: white !important;
  }
}

.page-menu--item-height {
  min-height: $page-menu--item-height !important;
  max-height: $page-menu--item-height !important;
  line-height: $page-menu--item-height !important;
}
</style>
<style src="@/assets/Roboto/roboto.css"></style>
<style src="@/styles/margins.css"></style>
<style src="@/styles/paddings.css"></style>
<style src="@/styles/active-filter.css"></style>
