import { createAsyncLoadComponent }
  from '@/plugins/vue/createAsyncLoadComponent';

// TODO: объединить маленькие чанки CSS
/**
 * TODO: объединить чанки повторяющиеся чанки JS
 * (из-за того, что они используются внутри antd)
 * вместо асинхронного импорта
 */
async function loadLayout() {
  const component = import('ant-design-vue/es/layout');
  await import('ant-design-vue/es/layout/style/css.js');
  return component;
}

export const Layout = createAsyncLoadComponent(
  loadLayout,
);
Layout.Header = createAsyncLoadComponent(
  () => loadLayout()
    .then((result) => {
      return result.default.Header;
    }),
);
Layout.Content = createAsyncLoadComponent(
  () => loadLayout()
    .then((result) => {
      return result.default.Content;
    }),
);
Layout.Sider = createAsyncLoadComponent(
  () => loadLayout()
    .then((result) => {
      return result.default.Sider;
    }),
);


export const ConfigProvider = createAsyncLoadComponent(
  () => import('ant-design-vue/es/config-provider'),
);


export const Statistic = createAsyncLoadComponent(
  async () => {
    const component = import('ant-design-vue/es/statistic');
    await import('ant-design-vue/es/statistic/style/css.js');
    return component;
  },
);
Statistic.Countdown = createAsyncLoadComponent(
  () => import('ant-design-vue/es/statistic/Countdown.js'),
);


export const DatePicker = createAsyncLoadComponent(
  async () => {
    const component = import('ant-design-vue/es/date-picker');
    await import('ant-design-vue/es/date-picker/style/css.js');
    return component;
  },
);


export const Popover =
  async () => {
    const component = import('ant-design-vue/es/popover');
    await import('ant-design-vue/es/popover/style/css.js');
    return component;
  };


export const Rate =
  async () => {
    const component = import('ant-design-vue/es/rate');
    await import('ant-design-vue/es/rate/style/css.js');
    return component;
  };


// export const Row = createAsyncLoadComponent(
//   async () => {
//     const component = import('ant-design-vue/es/grid/Row.js');
//     await import('ant-design-vue/es/grid/style/css.js');
//     return component;
//   },
// );


// export const Col =
//   async () => {
//     const component = import('ant-design-vue/es/grid/Col.js');
//     await import('ant-design-vue/es/grid/style/css.js');
//     return component;
//   };


export const Tag =
  async () => {
    const component = import('ant-design-vue/es/tag');
    await import('ant-design-vue/es/tag/style/css.js');
    return component;
  };

export const Divider =
  async () => {
    const component = import('ant-design-vue/es/divider');
    await import('ant-design-vue/es/divider/style/css.js');
    return component;
  };


async function loadTabs() {
  const component = import('ant-design-vue/es/tabs');
  await import('ant-design-vue/es/tabs/style/css.js');
  return component;
}

export const Tabs = createAsyncLoadComponent(
  loadTabs,
);
Tabs.TabPane = createAsyncLoadComponent(
  () => loadTabs()
    .then((result) => {
      return result.default.TabPane;
    }),
);

// export function Button() {
//   return createLoader('button');
// }

// export function Menu() {
//   return createLoader('menu');
// }

// export function Button() {
//   return createLoader('button');
// }
