import axios from 'axios';
import { message } from 'ant-design-vue';

import { efunction } from '@/utils/empties';
import { $http } from '@/plugins/axios';


/** @type {import('node_modules/vuex/types/index').Module} */
export default {
  namespaced: true,
  state: {
    balance: null,
    getBalanceCanceler: efunction,
  },
  getters: {
    balanceText(state) {
      if (state.balance === null) {
        return 'Нет данных';
      }
      return `${state.balance} ₽`;
    },
  },
  mutations: {
    setBalance(state, value) {
      state.balance = value;
    },
    setGetBalanceCanceler(state, value) {
      state.getBalanceCanceler = value;
    },
    logout(state) {
      state.balance = null;
      state.getBalanceCanceler();
    },
  },
  actions: {
    async getBalance(store) {
      store.state.getBalanceCanceler();
      try {
        const request = await $http.get(`users/users/${store.rootState.userId}/`, {
          cancelToken: new axios.CancelToken((canceler_) => {
            store.commit('setGetBalanceCanceler', canceler_);
          }),
        });
        store.commit('setBalance', request.data.balance);
        return request.data.balance;
      } catch (error) {
        if ($http.isCancel(error)) {
          return;
          // throw undefined;
        }
        message.error(
          $http.parseError('Не удалось получить данные баланса', error),
          5,
        );
        // throw error;
      }
    },
  },
};
