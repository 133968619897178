/**
 * @template T
 * @param {T} string
 * @returns {T}
 */
export function trimIfString(string) {
  if (typeof string === 'string') {
    string = string.trim();
  }
  return string;
}


/**
 * @template T
 * @param {T} value
 * @returns {T}
 */
export function deepTrimInPlace(value) {
  if (value && typeof value === 'object') {
    for (const [key, _value] of Object.entries(value)) {
      if (typeof _value === 'string') {
        delete value[key];
        value[key.trim()] = _value.trim();
      } else {
        deepTrimInPlace(_value);
      }
    }
  }
}
