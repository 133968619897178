export const blurButton = document.createElement('button');
blurButton.blurNoArgs = () => { blurButton.blur(); };
blurButton.focusNoArgs = () => { blurButton.focus(); };
blurButton.blur = blurButton.blur.bind(blurButton);
blurButton.focus = blurButton.focus.bind(blurButton);

blurButton.style.opacity = '0';
blurButton.style.width = '0';
blurButton.style.height = '0';
blurButton.style.padding = '0';
blurButton.style.margin = '0';
blurButton.style.border = 'none';
blurButton.style.position = 'fixed';

document.body.append(blurButton);
