/**
 * @template T
 * @param {T[]} array
 * @param {T} element
 */
export function ArrayDeleteIfHas(array, element) {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
  }
}
