import { deepTrimInPlace } from '@/utils/trim';


/**
 * @param {import('axios').AxiosRequestConfig} requestConfig
 * 
 * @return {import('axios').AxiosRequestConfig}
 */
export function autoTrimInterseption(requestConfig) {
  if (!requestConfig.$disableTrim && requestConfig.data) {
    deepTrimInPlace(requestConfig.data);
  }
  return requestConfig;
}
