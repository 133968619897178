// import type {
// ExtendedFile,
// ExtendedFileCopy,
// StubLoadingState,
// } from '@/stores/uploadManagerStore';
import { $http } from '@/plugins/axios';
// import type { ShallowRef } from 'vue';
import { efunction } from '@/utils/empties'


// Дополнительные поля в файлах
// Сессия загрузки
export const $uploadSession = Symbol('$uploadSession');
// Убрать из образца таблицы "SampleSettingTable" при удалении файла из списка
export const $deselectCallbackFromSample = Symbol('$deselectCallbackFromSample');
// "id" на сервере
export const $id = Symbol('$id')
// setter "id" на сервере
export const $setId = Symbol('$setId');
export function setId(
  // this: ExtendedFile | ExtendedFileCopy,
  id/* : (ExtendedFile | ExtendedFileCopy)[typeof $id] */,
) {
  this[$id] = id;
}


export const stubLoadingState/* : Omit<StubLoadingState, 'loadedBytes'> */ = {
  fileName: '',
  // status: { value: 'success' }/*  as ShallowRef */,
  status: 'success',
  // description: { value: 'Загрузка завершена' }/*  as ShallowRef */,
  description: 'Загрузка завершена',
  loadCanceler: $http.eAbortController,
  requestWithRetryTimeoutId: 0,
  object_key: null,
  loadedChunks: 1,
  totalСhunks: 1,
  startLoad: efunction,
  prepareToRestartLoading: efunction,
  callbacks: {
    success: efunction,
    error: efunction,
  },
};
