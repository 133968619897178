import Vue from 'vue';
// import VueCompositionAPI from '@vue/composition-api';
// import {
//   createPinia,
//   PiniaVuePlugin,
// } from 'pinia';

import router from '@/router';
import store from '@/store';
import '@/plugins/antd';
import '@/plugins/axios';
import App from '@/App.vue';
import { blurButton } from '@/plugins/blurButton';
import { StaticNode } from '@/components/StaticNode';


Vue.config.productionTip = false;

Vue.component('StaticNode', StaticNode);

// Vue.use(VueCompositionAPI);
// Vue.use(PiniaVuePlugin);

// const pinia = createPinia();

new Vue({
  router,
  store,
  // pinia,
  // Распространяется так, чтобы избежать дублирования через импорты
  provide: { blurButton },
  render: (h) => h(App),
})
  .$mount('#app');
