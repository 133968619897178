/**
 * @param {import('@/router/SimpleRouteConfig').SimpleRouteConfig} path
 * @returns {import('node_modules/vue-router/types/index').RouteConfig}
 */
export default function createLazyRoute(path) {
  const result = {
    ...path,
    component: () => import(`@/views/${path.component}/index.vue`),
  };
  if (path.children) {
    result.children = path.children.map(createLazyRoute);
  }
  return result;
}
