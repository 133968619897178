// Если загрузка компонента не удалась
/* eslint-disable object-curly-newline */
const ErrorLoadingComponent_props = {
  style: 'background-color: #fff1f0; border: 1px solid #ffa39e; padding: 12px;',
};
/* eslint-enable object-curly-newline */

const ErrorLoadingComponent = {
  render(h) {
    return h(
      'div',
      ErrorLoadingComponent_props,
      [
        h('h5', ['Ошибка']),
        'Не удалось загрузить компонент',
      ],
    );
  },
};


// Иконка загрузки компонента
const LoadingComponent_props1 = {
  style: (
    'display: inline-block;' +
    'text-align: center;' +
    'font-size: 14px;' +
    'width: 100%;'
  ),
};
const LoadingComponent_props2 = { style: 'position: static;' };
const LoadingComponent_props3 = {
  style: (
    'transform: rotate(45deg);' +
    'animation: rotate_ 1.2s linear infinite;' +
    'font-size: 20px;' +
    'display: inline-block;' +
    'width: 1em;' +
    'height: 1em;'
  ),
};
const LoadingComponent_props4_style = (
  'animation: spinMove_ 1s linear infinite alternate;' +
  'position: absolute;' +
  'width: 7.5px;' +
  'height: 7.5px;' +
  'background-color: #1890ff;' +
  'border-radius: 100%;' +
  'opacity: .3;'
);
/* eslint-disable object-curly-newline */
const LoadingComponent_props4_1 = {
  style: LoadingComponent_props4_style + 'left: 0; top: 0;',
};
/* eslint-enable object-curly-newline */
const LoadingComponent_props4_2 = {
  style: LoadingComponent_props4_style +
    'animation-delay: .4s; right: 0; top: 0;',
};
const LoadingComponent_props4_3 = {
  style: LoadingComponent_props4_style +
    'animation-delay: .8s; right: 0; bottom: 0;',
};
const LoadingComponent_props4_4 = {
  style: LoadingComponent_props4_style +
    'animation-delay: 1.2s; left: 0; bottom: 0;',
};

// Добавление анимаций
const styleElement = document.createElement('style');
styleElement.append(`
@keyframes rotate_ {
  to {
    transform: rotate(405deg);
  }
}
@keyframes spinMove_ {
  to {
    opacity: 1;
  }
}
`);
document.body.append(styleElement);

/*
  Копирование компонента "antd"
<div class="d-inline-block text-center">
  <div class="ant-spin ant-spin-spinning">
    <span class="ant-spin-dot ant-spin-dot-spin">
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
      <i class="ant-spin-dot-item"></i>
    </span>
  </div>
</div>
*/
const LoadingComponent = {
  render(h) {
    return h(
      'div',
      LoadingComponent_props1,
      [
        h(
          'div',
          LoadingComponent_props2,
          [
            h(
              'span',
              LoadingComponent_props3,
              [
                h('i', LoadingComponent_props4_1),
                h('i', LoadingComponent_props4_2),
                h('i', LoadingComponent_props4_3),
                h('i', LoadingComponent_props4_4),
              ],
            ),
          ],
        ),
      ],
    );
  },
};


/**
 * @typedef {(
 *  ReturnType<import('vue/types/options').AsyncComponentFactory>['component']
 * )} ComponentPromise
 * 
 * @param {() => ComponentPromise} getComponentPromise
 *
 * @return {import('vue/types/options').AsyncComponentFactory}
 */
export function createAsyncLoadComponent(getComponentPromise) {
  return () => ({
    component: getComponentPromise(),
    delay: 0,
    loading: LoadingComponent,
    error: ErrorLoadingComponent,
  });
}
