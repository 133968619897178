/**
 * @template KeyType
 * @template ValueType
 * 
 * @param {Record<KeyType, ValueType>} object 
 * @returns {{
 *  value: KeyType,
 *  label: ValueType,
 * }[]}
 */
export function objectToOptions(object) {
  const result = [];
  for (const entry of Object.entries(object)) {
    result.push({
      value: entry[0],
      label: entry[1],
    });
  }
  return result;
}
