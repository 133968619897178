import { message } from 'ant-design-vue';

import router from '@/router';
import { $http } from '@/plugins/axios';


/** @type {import('node_modules/vuex/types/index').Module} */
export default {
  namespaced: true,
  state: { mutationsBase: null },
  mutations: {
    setMutationsBase(state, value) {
      state.mutationsBase = value;
    },
    logout(state) {
      state.mutationsBase = null;
    },
  },
  actions: {
    async getMutationsBase(store, id) {
      if (store.state.mutationsBase && store.state.mutationsBase.id === Number(id)) {
        return store.state.mutationsBase;
      }

      store.commit('setMutationsBase', null);
      try {
        const response = await $http.get(`projects/categories/${id}/`);
        store.commit('setMutationsBase', response.data);
        return response.data;
      } catch (error) {
        if ($http.isCancel(error)) {
          throw undefined;
        }

        if (error && error.response && error.response.status === 404) {
          message.error('Такая база мутаций не найдена', 5);
          router.push({ name: 'База мутаций' });
          throw undefined;
        }
        
        message.error(
          $http.parseError(
            `Не удалось получить данные базы мутаций "${id}"`,
            error,
          ),
          5,
        );
        throw error;
      }
    },
  },
};
