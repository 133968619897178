/** @type {import('moment').Moment} */
let moment;

/** @param {import('moment')} module */
function returnDefault(module) {
  moment = module.default;
  return moment;
}

export function importMoment() {
  return import('moment').then(returnDefault);
}

export function getMoment() {
  return moment;
}
