const KB = 1024;
const MB = KB * 1024;
const GB = MB * 1024;
/**
 * Переводит байты в Кило-, Мега- или Гигабайты
 * 
 * @param {number} bytes
 * @return {string}
 */
export default function translateBytes(bytes) {
  if (bytes >= GB) {
    return `${(bytes / GB).toFixed(2)} ГБ`;
  }
  if (bytes >= MB) {
    return `${(bytes / MB).toFixed(2)} МБ`;
  }
  if (bytes >= KB) {
    return `${(bytes / KB).toFixed(2)} КБ`;
  }
  return `${bytes} Б`;
}
