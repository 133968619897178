import axios from 'axios';
import qs from 'qs';
import { message } from 'ant-design-vue';

import router from '@/router/index';
import { autoTrimInterseption } from '@/plugins/axios/autoTrim';
import store from '@/store';
import { efunction } from '@/utils/empties';


const qsOptions = Object.freeze({ arrayFormat: 'comma' });
const instanceParams = Object.freeze({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api`,
  timeout: 60000,
  paramsSerializer(params) {
    return qs.stringify(params, qsOptions);
  },
});


/** @type {AbortController} */
const eAbortController = Object.freeze({ abort: efunction });

function logError(error) {
  if (!axios.isCancel(error)) {
    console.log(error);
  }
  return Promise.reject(error);
}

function isCancel(error) {
  return !error || error.name === 'AbortError' || axios.isCancel(error);
}

function ifNotCancel(callback) {
  return function(error) {
    if (error && !axios.isCancel(error)) {
      callback(error);
    }
  }
}

function nullErrorIfCancelOrUnauthorized(error) {
  // Если это отмена запроса, то ошибка пустая
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  // Если это ошибка авторизации, то ошибка пустая.
  if (
    axios.isAxiosError(error) && (
      error.response?.status === 401 || error.response?.status === 403
    ) &&
    error.config && (
      error.config.url !== 'users/login/' &&
      error.config.url !== 'users/register/' &&
      error.config.url !== 'users/reset-password/'
    )
  ) {
    message.error({
      content: 'Учётные данные не были предоставлены',
      duration: 5,
      key: 'anauthorized',
    });
    store.dispatch('logout');
    if (router.currentRoute.name !== 'Логин') {
      router.push('/');
    }
    return Promise.reject();
  }

  return Promise.reject(error);
}

function parseError(
  premessage,
  error,
  fieldTranslation,
) {
  let message = 'программная ошибка, пожалуйста, обратитесь в поддержку';
  if (error.response) {
    if (error.response.status < 500) {
      try {
        message = parseErrorData(error.response.data, fieldTranslation);
      } catch (TypeError) {
        // если неверный формат ответа от сервера
        console.log(error.response.data);
        message = parseServerError();
      }
    } else {
      message = parseServerError(error.response.status);
    }
  } else if (error.toJSON) {
    message = parseServerError(error.toJSON().message);
  } else {
    // const errorText = (error as Error)?.stack || String(error);
    const errorText = String(error?.stack || error);
    // Добавить 100 символов текста ошибки
    message = `${message} "${errorText.slice(0, 100)}${errorText.length > 100 ? '...' : ''}"`;
    console.error('Программная ошибка:', errorText);
  }

  return premessage ? premessage + ': ' + message : message;
}

/** @return {import('./index').AxiosInstanceExtended} */
export function createInstance() {
  /** @type {import('./index').AxiosInstanceExtended} */
  const http = axios.create(instanceParams);
  http.interceptors.request.use(autoTrimInterseption);
  http.interceptors.response.use(
    undefined,
    logError,
  );
  http.interceptors.response.use(
    undefined,
    nullErrorIfCancelOrUnauthorized,
  );
  http.ifNotCancel = ifNotCancel;
  http.isAxiosCancel = axios.isCancel;
  http.parseError = parseError;
  http.isCancel = isCancel;
  http.eAbortController = eAbortController;
  return http;
}

// interface ErrorData {
//   message: string;
//   code: string;
// }
// type MultipleErrorsData = { [errorName: string]: ErrorData[] };

// ErrorData, string
function getErrorMessageFromArray(errors, field) {
  // string[]
  const result = [];
  for (const error of errors) {
    if (error && error.message) {
      result.push(error.message);
    }
  }
  return `${field}: ${result.join(', ')}`;
}

function parseErrorData(data, fieldTranslation) {  
  if (data && typeof data === 'object') {
    if (Array.isArray(data)) {
      return getErrorMessageFromArray(data);
    }

    if ('detail' in data) {
      return String(data.detail);
    }

    // const result: string[] = [];
    const result = [];
    for (const field in data) {
      // MultipleErrorsData
      const errors = data[field];
      if (!Array.isArray(errors)) {
        continue;
      }
      // TODO: сделать возможность перевода "field"
      result.push(getErrorMessageFromArray(errors, fieldTranslation?.[field] || field));
    }
    return `\n${result.join('\n')}`;
  }
  return String(data);
}

function parseServerError(status) {
  const statusString = status ? (` ${status}`) : '';
  return `серверная ошибка${statusString}. Ведутся технические работы`;
}
